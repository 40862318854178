<template>
  <div class="action-buttons-container">
    <z-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      :text="$t('welcome.back')"
      width="100"
      :disabled="isLoading"
      @click="$emit('back')"
    />
    <z-btn
      id="facial-recognition"
      primary
      icon="$face_id"
      class="button-recognition"
      :width="$vuetify.breakpoint.smAndDown ? '100%' : '240px'"
      :text="$t('welcome.access_facial_recognition')"
      :is-loading="isLoading"
      @click="
        $vuetify.breakpoint.smAndDown
          ? $router.push({ name: 'facial-recognition-instructions' })
          : $emit('open-recognition-modal', true)
      "
    />

    <z-btn
      v-if="$vuetify.breakpoint.smAndDown"
      class="button-back"
      :text="$t('welcome.back')"
      :disabled="isLoading"
      @click="$emit('back')"
    />
  </div>
</template>

<script>
export default {
  props: {
    search: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
