<template>
  <div>
    <z-input
      id="email-input"
      key="email"
      hide-details
      placeholder="seuemail@gmail.com"
      type="email"
      :label="$t('welcome.insert_your_mail')"
      :value="search.params.contact"
      :disabled="isLoading"
      @input="$emit('change', { 'params.contact': $event })"
    />
    <z-input
      id="last-name-input"
      key="last-name"
      hide-details
      :label="$t('welcome.last_name')"
      :value="search.params.lastName"
      :disabled="isLoading"
      @input="$emit('change', { 'params.lastName': $event.trim() })"
    />
  </div>
</template>

<script>
export default {
  props: {
    search: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
