<template>
  <z-card-modal
    persistent
    :opened="opened"
    width="400px"
    height="auto"
    class="card-container-recognition"
  >
    <template #content>
      <section class="main-content-instructions-modal">
        <div class="d-flex align-self-center my-4">
          <illustration-facial-recognition></illustration-facial-recognition>
        </div>

        <h4 class="body-text text-center">
          {{ $t("recognition.recognition_instructions_title") }}
        </h4>

        <p class="body2 text-center my-4">
          {{ $t("recognition.recognition_instructions_description") }}
        </p>
        <div class="text-content">
          <ul v-for="list in listItems" :key="list.text">
            <li class="d-flex align-center mb-3">
              <z-icon class="pass-alert-icon mx-2" size="18px" color="green">
                $check_in_a_circle
              </z-icon>
              <p class="mb-0 body2">{{ list.text }}</p>
            </li>
          </ul>
        </div>
      </section>
      <div class="btn-container">
        <z-btn
          class="letsgo-btn my-2"
          primary
          width="20%"
          text="Continuar"
          @click="$emit('click:continue')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { computed } from "vue"

import i18n from "@/i18n"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const listItems = computed(() => {
      return [
        { text: i18n.tc("recognition.recognition_instructions_list1") },
        { text: i18n.tc("recognition.recognition_instructions_list2") },
        { text: i18n.tc("recognition.recognition_instructions_list3") },
        { text: i18n.tc("recognition.recognition_instructions_list4") },
      ]
    })

    return {
      listItems,
    }
  },
}
</script>

<style lang="scss" scoped>
//desktop
@media screen and (min-width: 786px) {
  img {
    width: 70%;
    margin: 0 auto;
  }

  .btn-container {
    display: flex;
    margin-top: 0;
  }

  .letsgo-btn {
    width: 100px !important;
    margin: 0 15px;
  }

  .main-content-instructions-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 50px;
  }

  .text-content {
    width: 90%;
    h4 {
      margin-bottom: 30px;
    }
    ul {
      padding-left: 0 !important;
    }
  }
}
</style>
