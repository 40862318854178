<template>
  <div class="name-search-container">
    <div class="checkin-checkout-dates">
      <z-input-date
        id="name-input-date"
        key="checkin-date"
        hide-details
        eager
        :offset-y="false"
        :label="$t('welcome.checkin_date')"
        :format="languageFormat"
        :value="search.params.checkinDate"
        :disabled="isLoading"
        @input="$emit('change', { 'params.checkinDate': $event })"
      />
      <z-input-date
        id="name-input-date"
        key="checkout-date"
        hide-details
        eager
        :label="$t('welcome.checkout_date')"
        :format="languageFormat"
        :value="search.params.checkoutDate"
        :disabled="isLoading"
        @input="$emit('change', { 'params.checkoutDate': $event })"
      />
    </div>
    <z-input
      id="first-name-input"
      key="first-name"
      hide-details
      :label="$t('welcome.first_name')"
      :value="search.params.name"
      :disabled="isLoading"
      @input="$emit('change', { 'params.name': $event.trim() })"
    />
    <z-input
      id="last-name-input"
      key="last-name"
      hide-details
      :label="$t('welcome.last_name')"
      :value="search.params.lastName"
      :disabled="isLoading"
      @input="$emit('change', { 'params.lastName': $event.trim() })"
    />
  </div>
</template>

<script>
import { computed } from "vue-demi"
import { useNamespacedState } from "vuex-composition-helpers"

import { LANGS } from "@/consts"
export default {
  props: {
    search: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { currentLang } = useNamespacedState("layout", ["currentLang"])

    const languageFormat = computed(() => {
      return LANGS.find((lang) => lang.value === currentLang.value).full
    })

    return { languageFormat }
  },
}
</script>

<style lang="scss" scoped>
.name-search-container {
  display: grid;
  row-gap: 20px;
}

.checkin-checkout-dates {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  column-gap: 10px;
}

@media screen and (min-width: 1024px) {
  .checkin-checkout-dates {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
