<template>
  <z-card-modal
    width="350px"
    :opened="opened"
    :title="$t('modal.locked_search_title')"
  >
    <template #content>
      <div class="locked-modal-content">
        <z-icon class="error-icon" size="84" color="red">$x_in_a_circle</z-icon>
        <div class="body2">{{ $t("modal.search_locked") }}</div>
        <z-btn primary text="OK" @click="$emit('close')" />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.locked-modal-content {
  display: grid;
  row-gap: 15px;
  margin-top: 15px;

  .error-icon {
    justify-self: center;
  }
}
</style>
