<template>
  <div :style="search.type === 'name' ? { 'min-height': '455px' } : {}">
    <find-your-reservation-card
      :current-language="'pt'"
      :is-loading="isLoading"
      :search="search"
      :document-types="searchDocumentTypes"
      :options="searchMethods"
      :recaptcha="recaptcha"
      @resolved:captcha="recaptcha.resolved = true"
      @change:type="onChangeSearchType"
      @change="changeSearch"
      @submit="onClickSearch"
      @open-facial-info="handleFacialInfoModal($event)"
      @back="$store.dispatch('flow/reservation/previous')"
      @open-recognition-modal="isFacialInstruction = $event"
      @change:open-guidance-modal="onChangeOpenGuidanceModal"
    />
    <find-result-modal
      :opened="notFoundedModal"
      :founded="false"
      @click:ok="notFoundedModal = false"
    />
    <facial-info-modal
      v-if="isFacialInfoModalOpen"
      :opened="isFacialInfoModalOpen"
      @click:close="isFacialInfoModalOpen = false"
    />
    <facial-recognition-instruction-modal
      v-if="isFacialInstruction"
      :opened="isFacialInstruction"
      :current-lang="currentLang.charAt(0).toUpperCase() + currentLang.slice(1)"
      @click:continue="handleRecognitionFlux"
    />
    <take-picture-modal
      v-if="isTakePictureModalOpen"
      :opened="isTakePictureModalOpen"
      :action-btn-text="$t('recognition.facial_takepic_buttonScan')"
      :is-loading-face="isLoading"
      @scan:click="onClickScanFace"
      @close="onClickClosePictureModal"
    />
    <facial-recognition-error-modal
      :opened="openModalFacialError"
      :status-code="Number(statusCode)"
      @click:continue="handleContinueError($event)"
    />
    <search-methods-guidance-modal
      :opened="isGuidanceModalOpen"
      :search-type="searchType"
      @click:close="isGuidanceModalOpen = false"
    />
    <expired-reservation-modal
      :opened="isExpiredReservationModalOpen"
      :res-status="reservation?.resStatus"
      @click:close="$router.push({ name: 'welcome-menu' })"
    />
    <locked-modal :opened="isLockedModal" @close="isLockedModal = false" />
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue"
import {
  useNamespacedActions,
  useNamespacedGetters,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import ExpiredReservationModal from "@/components/Alert/AlertTypes/ExpiredReservationModal.vue"
import { useStoreAction, useVuetify } from "@/composables"
import { useTracking } from "@/composables"
import { Time } from "@/consts"
import i18n from "@/i18n"
import { SearchTypes } from "@/store/search"

import TakePictureModal from "../../components/TakePicture/components/TakePictureModal.vue"
import FindYourReservationCard from "./components/FindYourReservationCard"
import FacialInfoModal from "./components/modals/FacialInfoModal.vue"
import FacialRecognitionError from "./components/modals/FacialRecognitionError.vue"
import FacialRecognitionInstructionModal from "./components/modals/FacialRecognitionInstructionModal.vue"
import FindResultModal from "./components/modals/FindResultModal.vue"
import LockedModal from "./components/modals/LockedModal.vue"
import SearchMethodsGuidanceModal from "./components/modals/SearchMethodsGuidanceModal.vue"

export default {
  name: "FindYourReservation",
  components: {
    LockedModal,
    FindResultModal,
    FindYourReservationCard,
    FacialInfoModal,
    FacialRecognitionInstructionModal,
    TakePictureModal,
    FacialRecognitionErrorModal: FacialRecognitionError,
    SearchMethodsGuidanceModal,
    ExpiredReservationModal,
  },
  // eslint-disable-next-line
  setup() {
    const recaptcha = reactive({
      required: false,
      resolved: false,
    })

    const vuetify = useVuetify()

    const isLockedModal = ref(false)
    const notFoundedModal = ref(false)
    const isFacialInfoModalOpen = ref(false)
    const isFacialInstruction = ref(false)
    const isTakePictureModalOpen = ref(false)
    const faceIdFound = ref(false)
    const openModalFacialError = ref(false)
    const isGuidanceModalOpen = ref(false)
    const searchType = ref("")
    const isExpiredReservationModalOpen = ref(false)

    const { changeFaceId } = useNamespacedMutations("search", ["changeFaceId"])
    const { currentLang } = useNamespacedState("layout", ["currentLang"])

    const { trackEvent } = useTracking()

    const { search, isInvalidOrExpired } = useNamespacedState("search", [
      "search",
      "isInvalidOrExpired",
    ])
    const { searchMethods } = useNamespacedGetters("search", ["searchMethods"])
    const { searchDocumentTypes } = useNamespacedGetters("parameters", [
      "searchDocumentTypes",
    ])
    const { countries } = useNamespacedState("parameters", ["countries"])
    const { changeSearch } = useNamespacedMutations("search", ["changeSearch"])
    const { searchReservation, isLoading, hasError, statusCode } =
      useStoreAction("search", "searchReservation")
    const { onChangeSearchType } = useNamespacedActions("search", [
      "onChangeSearchType",
    ])
    const { sendFaceImage } = useStoreAction("search", "sendFaceImage")
    const { sendNotification } = useNamespacedActions("notifications", [
      "sendNotification",
    ])
    const { reservation } = useNamespacedState("reservation", ["reservation"])

    const handleFacialInfoModal = (event) => {
      if (event.type === "facial_recognition") {
        isFacialInfoModalOpen.value = !isFacialInfoModalOpen.value
      }
    }

    const handleRecognitionFlux = () => {
      isFacialInstruction.value = false
      isTakePictureModalOpen.value = true
    }

    const handleContinueError = (statusCode) => {
      if (statusCode === 408) {
        openModalFacialError.value = false
        isTakePictureModalOpen.value = true
      } else {
        isTakePictureModalOpen.value = false
        openModalFacialError.value = false
      }
    }

    const onClickScanFace = async (event) => {
      changeFaceId({
        currentImage: event,
      })
      await sendFaceImage()
      await searchReservation()

      if (statusCode === 200) {
        faceIdFound.value = true
      }
      if (hasError.value) {
        openModalFacialError.value = true
        isTakePictureModalOpen.value = false
      }
    }

    const onClickClosePictureModal = () => {
      isTakePictureModalOpen.value = false
      isLoading.value = false
      faceIdFound.value = false
    }

    const onClickSearch = async () => {
      trackEvent("click_search_" + search.value.type)
      await searchReservation()

      if (isInvalidOrExpired.value) {
        isExpiredReservationModalOpen.value = true
      }

      if (hasError.value) {
        switch (statusCode.value) {
          case 429: // too many requests
            recaptcha.required = true
            break

          case 423: // locked
            if (vuetify.breakpoint.smAndDown) {
              isLockedModal.value = true
            } else {
              sendNotification({
                message: i18n.tc("modal.search_locked"),
                duration: 6 * Time.Seconds,
                color: "var(--red-600)",
              })
            }
            break

          default:
            notFoundedModal.value = true
            break
        }
      }
    }

    const onChangeOpenGuidanceModal = (type) => {
      if (type !== SearchTypes.FACIAL_RECOGNITION) {
        isGuidanceModalOpen.value = true
        searchType.value = type
      }
    }

    onMounted(() => {
      if (Array.isArray(searchMethods.value) && searchMethods.value.length > 0)
        onChangeSearchType(searchMethods.value[0].value)
    })

    return {
      recaptcha,
      isLockedModal,
      isLoading,
      statusCode,
      search,
      searchMethods,
      searchDocumentTypes,
      changeSearch,
      notFoundedModal,
      faceIdFound,
      searchType,
      currentLang,

      handleFacialInfoModal,
      handleRecognitionFlux,
      handleContinueError,

      isFacialInfoModalOpen,
      isFacialInstruction,
      isTakePictureModalOpen,
      isGuidanceModalOpen,
      countries,

      onChangeOpenGuidanceModal,
      onChangeSearchType,
      onClickSearch,
      onClickScanFace,
      onClickClosePictureModal,

      openModalFacialError,
      changeFaceId,
      isExpiredReservationModalOpen,
      reservation,
    }
  },
}
</script>
