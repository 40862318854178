<template>
  <z-card-modal :opened="opened" persistent width="463px">
    <template #content>
      <div data-cy="facial-info-modal">
        <h5 class="text-uppercase my-2">
          {{ $t("recognition.facial_infoModal_title") }}
        </h5>
        <p
          class="body-text body-2 my-3"
          v-html="$t('recognition.facial_infoModal_description')"
        ></p>

        <p class="body-text body-2 mt-2">
          {{ $t("recognition.facial_infoModal_description2") }}
        </p>
        <div class="modal-btn-container">
          <z-btn
            class="modal-btn"
            primary
            width="100%"
            :text="$t('modal.button_ok')"
            @click="$emit('click:close')"
          />
        </div>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.checklist-img {
  text-align: center;
}

.modal-btn {
  margin-top: 20px;
}

@media screen and (min-width: 786px) {
  .modal-btn-container {
    text-align: end;
  }

  .modal-btn {
    width: 155px !important;
  }
}
</style>
