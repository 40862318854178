<template>
  <div>
    <z-input
      id="reservation-input"
      key="reservation-number"
      hide-details
      :label="$t('welcome.insert_your_reservation_code')"
      :value="search.params.reservationNumber"
      :disabled="isLoading"
      @input="$emit('change', { 'params.reservationNumber': $event })"
    />
    <z-input
      id="last-name-input"
      key="last-name"
      hide-details
      :label="$t('welcome.last_name')"
      :value="search.params.lastName"
      :disabled="isLoading"
      @input="$emit('change', { 'params.lastName': $event.trim() })"
    />
  </div>
</template>

<script>
export default {
  props: {
    search: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
