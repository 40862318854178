<template>
  <z-card
    class="find-your-reservation-card recognition"
    :width="search.type === 'name' ? '450px' : '390px'"
  >
    <template #content>
      <h2>
        {{ $t("welcome.find_your_reservation_title") }}
      </h2>
      <div class="body-text">
        {{ $t("welcome.find_your_reservation_description") }}:
      </div>

      <z-select
        id="select-search-method"
        hide-details
        :label="$t('welcome.select_search_method')"
        :items="options"
        :value="search.type"
        :disabled="isLoading"
        @change="onChangeSearchType"
      />

      <component
        :is="currentSearchType"
        class="search-type-container"
        :search="search"
        :is-loading="isLoading"
        :document-types="documentTypes"
        @open-recognition-modal="$emit('open-recognition-modal', true)"
        @change="$emit('change', $event)"
      />

      <recaptcha
        v-if="recaptcha.required"
        @resolved="$emit('resolved:captcha')"
      />

      <div
        v-if="search.type !== 'facial_recognition'"
        class="action-buttons-container"
      >
        <z-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          :text="$t('welcome.back')"
          :disabled="isLoading"
          @click="$emit('back')"
        />
        <z-btn
          id="search"
          primary
          :disabled="
            (recaptcha.required && !recaptcha.resolved) ||
            v$.$invalid ||
            (v$.$invalid && statusCode == 429 && !isCaptchaVerified.value)
          "
          :text="$t('welcome.proceed')"
          :is-loading="isLoading"
          @click="$emit('submit')"
        />
        <z-btn
          v-if="$vuetify.breakpoint.smAndDown"
          :text="$t('welcome.back')"
          :disabled="isLoading"
          @click="$emit('back')"
        />
      </div>

      <language-select v-if="$vuetify.breakpoint.smAndDown" />
    </template>
  </z-card>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { email, required } from "@vuelidate/validators"
import { computed, toRefs } from "vue"

import { useValidations } from "@/composables"
import { SearchTypes } from "@/store/search"

import DocumentSearch from "./search-types/DocumentSearch.vue"
import EmailSearch from "./search-types/EmailSearch.vue"
import FacialRecognitionSearch from "./search-types/FacialRecognitionSearch.vue"
import NameSearch from "./search-types/NameSearch.vue"
import PhoneNumberSearch from "./search-types/PhoneNumberSearch.vue"
import ReservationNumberSearch from "./search-types/ReservationNumberSearch.vue"

export default {
  components: {
    DocumentSearch,
    NameSearch,
    PhoneNumberSearch,
    EmailSearch,
    FacialRecognitionSearch,
    ReservationNumberSearch,
  },
  props: {
    search: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    documentTypes: {
      type: Array,
      required: true,
    },
    recaptcha: {
      type: Object,
      default: () => ({
        required: false,
        resolved: false,
      }),
    },
  },
  setup(props, { emit }) {
    const { search } = toRefs(props)
    const { withI18nMessage, getErrorMessages } = useValidations()

    const currentSearchType = computed(() => {
      return {
        [SearchTypes.RESERVATION]: "reservation-number-search",
        [SearchTypes.NAME]: "name-search",
        [SearchTypes.DOCUMENT]: "document-search",
        [SearchTypes.PHONE]: "phone-number-search",
        [SearchTypes.MAIL]: "email-search",
        [SearchTypes.FACIAL_RECOGNITION]: "facial-recognition-search",
      }[search.value.type]
    })

    const rules = computed(() => {
      switch (search.value.type) {
        case SearchTypes.RESERVATION:
          return {
            reservationNumber: { required },
            lastName: { required },
          }

        case SearchTypes.NAME:
          return {
            name: {
              required: withI18nMessage(required, {
                messagePath: () => "validations.required_reservation_number",
              }),
            },
            lastName: { required },
            checkinDate: { required },
            checkoutDate: { required },
          }

        case SearchTypes.MAIL:
          return {
            contact: { required, email },
            lastName: { required },
          }

        case SearchTypes.PHONE:
          return {
            contact: { required },
            lastName: { required },
          }

        case SearchTypes.DOCUMENT:
          return {
            documentNumber: { required },
          }
        default:
          return {}
      }
    })

    const v$ = useVuelidate(rules, search.value.params, { $autoDirty: true })

    const onChangeSearchType = (event) => {
      emit("change:type", event)
      emit("change:open-guidance-modal", event)
      emit("open-facial-info", { type: event })
    }

    return {
      currentSearchType,
      v$,
      onChangeSearchType,
      getErrorMessages,
    }
  },
}
</script>

<style lang="scss" scoped>
.find-your-reservation-card {
  max-width: 450px;
  padding: 20px;
  display: grid;
  row-gap: 20px;
  text-align: center;

  .body-text {
    text-align: start;
  }
}

.search-type-container {
  display: grid;
  row-gap: 20px;
}

.action-buttons-container {
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: 1fr;
}

@media screen and (max-width: 960px) {
  .find-your-reservation-card {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .action-buttons-container {
    grid-template-columns: 1fr 1fr;
  }

  .recognition {
    max-width: 500px;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .action-buttons-container {
    grid-auto-flow: dense;
    direction: rtl;
  }

  .button-back {
    width: 180px;
    margin: 0 auto;
  }

  .button-recognition {
    width: 260px !important;
  }
}
</style>
