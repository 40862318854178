<template>
  <z-card-modal
    class="find-result-modal"
    persistent
    :opened="opened"
    :width="$vuetify.breakpoint.xs ? '100%' : '400px'"
  >
    <template #content>
      <main v-if="founded" class="find-result-modal">
        <div class="card-title">
          {{ $t("welcome.reservation_founded_title") }}
        </div>
        <div class="body-text">
          {{ $t("welcome.reservation_founded_description") }}
        </div>
        <z-btn
          class="ok-btn"
          primary
          :text="$t('welcome.ok')"
          @click="$emit('click:ok')"
        />
      </main>

      <main v-else class="find-result-modal">
        <div class="card-title">
          {{ $t("welcome.reservation_not_founded_title") }}
        </div>
        <p
          class="body-text"
          v-html="$t('welcome.reservation_not_founded_description')"
        ></p>
        <div class="section">
          <z-icon color="primary">$face_id</z-icon>
          <p
            class="body-text"
            v-html="$t('welcome.reservation_not_founded_first_text')"
          ></p>
        </div>
        <div class="section">
          <z-icon color="primary">$devices_rounded</z-icon>
          <p
            class="body-text"
            v-html="$t('welcome.reservation_not_founded_second_text')"
          ></p>
        </div>
        <p
          class="body-text"
          v-html="$t('welcome.reservation_not_founded_bottom_text')"
        ></p>
        <z-btn
          class="ok-btn"
          primary
          :text="$t('welcome.ok')"
          @click="$emit('click:ok')"
        />
      </main>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    founded: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.find-result-modal {
  max-width: 400px;
  display: grid;
  gap: 15px;
}

.section {
  display: grid;
  grid-auto-flow: column;
  gap: 15px;
}

.ok-btn {
  justify-self: end;
  width: 155px;
}

@media screen and (max-width: 960px) {
  .ok-btn {
    width: 100%;
  }
}
</style>
