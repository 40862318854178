<template>
  <div>
    <z-phone-input
      id="phone-input"
      key="phone"
      hide-details
      is-mobile
      :countries="countries"
      :label="$t('welcome.insert_your_phone')"
      :value="search.params.contact"
      :disabled="isLoading"
      :current-country="search.params.currentCountry"
      @change:country="$emit('change', { 'params.currentCountry': $event })"
      @input="$emit('change', { 'params.contact': $event })"
    />
    <z-input
      id="last-name-input"
      key="last-name"
      hide-details
      :label="$t('welcome.last_name')"
      :value="search.params.lastName"
      :disabled="isLoading"
      @input="$emit('change', { 'params.lastName': $event.trim() })"
    />
  </div>
</template>

<script>
import countries from "@zoox-ui/data/countries.json"

export default {
  props: {
    search: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { countries }
  },
}
</script>
