<template>
  <div class="document-search-container">
    <z-select
      hide-details
      :label="$t('welcome.document_type')"
      :disabled="isLoading"
      :value="search.params.documentTypeId"
      :items="documentTypes"
      @change="
        $emit('change', {
          'params.documentTypeId': $event,
          'params.documentNumber': '',
        })
      "
    />
    <z-input
      v-if="documentMask"
      key="document-number-masked"
      hide-details
      :label="$t('welcome.insert_your_document')"
      :disabled="isLoading"
      :value="search.params.documentNumber"
      :mask="documentMask"
      @input="$emit('change', { 'params.documentNumber': $event })"
    />
    <z-input
      v-else
      key="document-number-unmasked"
      hide-details
      :label="$t('welcome.insert_your_document')"
      :disabled="isLoading"
      :value="search.params.documentNumber"
      @input="$emit('change', { 'params.documentNumber': $event })"
    />
  </div>
</template>

<script>
import find from "lodash.find"
import { computed, toRefs } from "vue"

export default {
  props: {
    search: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    documentTypes: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { search, documentTypes } = toRefs(props)

    const documentMask = computed(() => {
      const currentType = find(documentTypes.value, {
        value: search.value.params.documentTypeId,
      })

      return currentType?.mask
    })

    return {
      documentMask,
    }
  },
}
</script>

<style lang="scss" scoped>
.document-search-container {
  display: grid;
  row-gap: 20px;
}
</style>
