<template>
  <z-card-modal
    persistent
    :title="$t(`welcome.search_by_${searchType}_modal_title`)"
    :opened="opened"
    width="400px"
  >
    <template #content>
      <p
        class="mt-3 mb-3"
        v-html="$t(`welcome.search_by_${searchType}_modal_text_body`)"
      ></p>
      <div class="btn-container">
        <z-btn
          class="modal-btn"
          primary
          text="ok"
          width="100%"
          @click="$emit('click:close')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    searchType: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss">
.btn-container {
  display: flex;
}

@media screen and (min-width: 786px) {
  .btn-container {
    justify-content: flex-end;
  }

  .modal-btn {
    width: 155px !important;
  }
}
</style>
